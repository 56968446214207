import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { getInstanceName } from '../helpers/utils';
import styles from '../styles/pages/verifications.module.scss';
import { OtpOptionFormContent } from '../components/otp/otp-options-content';

const OtpPage = () => {
  return (
    <>
      <SEO title={`Otp - ${getInstanceName()}`} />
      <Layout hideHeader fluid pageWrapperClassName="page_wrapper">
        <div className="container">
          <div className={styles.wrapper}>
            <OtpOptionFormContent />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default OtpPage;
