import React from 'react';

export const WhatsappVerify = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1022_19535)">
        <path
          d="M12.003 0H11.997C5.3805 0 0 5.382 0 12C0 14.625 0.846 17.058 2.2845 19.0335L0.789 23.4915L5.4015 22.017C7.299 23.274 9.5625 24 12.003 24C18.6195 24 24 18.6165 24 12C24 5.3835 18.6195 0 12.003 0Z"
          fill="#4CAF50"
        />
        <path
          d="M18.9848 16.9453C18.6953 17.7628 17.5463 18.4408 16.6298 18.6388C16.0028 18.7723 15.1838 18.8788 12.4268 17.7358C8.90034 16.2748 6.62934 12.6913 6.45234 12.4588C6.28284 12.2263 5.02734 10.5613 5.02734 8.83928C5.02734 7.11728 5.90184 6.27878 6.25434 5.91878C6.54384 5.62328 7.02234 5.48828 7.48134 5.48828C7.62984 5.48828 7.76334 5.49578 7.88334 5.50178C8.23584 5.51678 8.41284 5.53778 8.64534 6.09428C8.93484 6.79178 9.63984 8.51378 9.72384 8.69078C9.80934 8.86778 9.89484 9.10778 9.77484 9.34028C9.66234 9.58028 9.56334 9.68678 9.38634 9.89078C9.20934 10.0948 9.04134 10.2508 8.86434 10.4698C8.70234 10.6603 8.51934 10.8643 8.72334 11.2168C8.92734 11.5618 9.63234 12.7123 10.6703 13.6363C12.0098 14.8288 13.0958 15.2098 13.4843 15.3718C13.7738 15.4918 14.1188 15.4633 14.3303 15.2383C14.5988 14.9488 14.9303 14.4688 15.2678 13.9963C15.5078 13.6573 15.8108 13.6153 16.1288 13.7353C16.4528 13.8478 18.1673 14.6953 18.5198 14.8708C18.8723 15.0478 19.1048 15.1318 19.1903 15.2803C19.2743 15.4288 19.2743 16.1263 18.9848 16.9453Z"
          fill="#FAFAFA"
        />
      </g>
      <defs>
        <clipPath id="clip0_1022_19535">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
