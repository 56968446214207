import React from 'react';
import InputRadio from '../input-radio';
import styles from './otp.module.scss';

// eslint-disable-next-line react/prop-types
export const OtpOption = ({ id, title, children, ...inputProps }) => {
  return (
    <div className={styles.otpOption}>
      <label htmlFor={id} className="flex-1 flex py-4 px-4 pointer">
        <div className={styles.iconsContainer}>
          <div className="flex">
            {children}
            <span className="font-bold ml-3">{title}</span>
          </div>
          <div>
            <InputRadio className={styles.radio} id={id} {...inputProps} />
          </div>
        </div>
      </label>
    </div>
  );
};
